/* .parent-container {
  height: 100%;
  overflow-y: auto;
} */

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 430px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    /* flex-wrap: wrap; */
  
  }
  
  .item {
    flex: 1 1 auto;
    margin-top: 10px;
    text-align: center;
  }
  
  .item > img {
  
    width: 200px;
  }
  
  
  .item-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 430px;
  }
  .barcode-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 430px;
  }
  .bar-code-item {
    flex: 1 1 auto;
    margin-top: 20px;
    margin-left: 75px;
  }
  .sub-item {
    flex: 1 1 50%;
    margin-left: 30px;
    margin-top: 20px;
  }
  .sub-item-second {
    flex: 1 1 50%;
    margin-left: 130px;
    margin-top: 20px;
  }
  
  
  .item-title{
    flex: 1 1 auto;
    margin-top: 20px;
  }
  .item-total {
    flex: 1 1 50%;
    margin-top: 20px;
  }
  .item-name {
    flex: 1 1 70%;
    margin-top: 20px;
  }
  .item-price{
    flex: 1 1 30%;
    margin-top: 20px;
  }
  .sub-item.content {
    flex: 1 1 50%;
    margin-left: 30px;
  }
  
  .dash{
    border: 0 none;
    border-top: 2px dashed #322f32;
    background: none;
    height:0;
    width: 429px;;
  }
  hr {
    margin-left: 0;
    margin-right: 0;
  }
  
  .offername {
    flex: 1 1 50%;
    text-align: left;
  }
  
  .offeramount {
    flex: 1 1 50%;
    text-align: center;
    margin-left: 55px;
  }