.table-container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background: #fff;
    padding: 40px;
    overflow-y: auto;
  }
  .item {
    flex: 1 1 33.33%;
    padding-left: 10px;
  }
  .logout {
    float: right;
  }
  .logout > span {
    padding: 10px;
    font-weight: 400;
    font-size: 21px;
    cursor: progress;
    color: #fff;
  }
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
  }
  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  tr:nth-child(even) {
    background-color: #dddddd;
  }