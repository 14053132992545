.ant-modal-content {
    border-radius: 7px;
};

.qrlogin-btn {
    width: 100%;
    background-color: #0083c6 !important;
    color: #ffffff !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    border: 1px solid #0083c6;
}