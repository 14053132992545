body {
  margin: 0px;
}

::-webkit-scrollbar {
  display: none !important;
}

.adv-block {
  height: 100vh;
  background-color: #0083c6;
  text-align: center;
}

.adv-block-div {
  padding: 6rem 3rem;
}

/* .adv-gif-div {
      background-color: #ffffff;
    border-radius: 50%;
  }
   */
.adv-gif {
  width: 220px !important;
  height: 200px;
  margin: 2rem 0rem;
  border-radius: 50%;
}

.get-all {
  margin-bottom: 4rem;
  font-size: 12px;
  color: #ffffff;
}

.inputs-row {
  /* height: 100vh; */
  padding-top: 20px;
  text-align: center;
}

.shopping-with {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
}

.inputs-block {
  height: 100vh;
  background-color: #ffffff;
  text-align: center;
}

.inputs-main-div {
  padding: 8rem 4rem;
}

.inputs-section {
  padding-left: 4rem;
  padding-right: 4rem;
}

.inputs-section .hello-again {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 600;
}

.please-login {
  font-size: 12px;
  color: gray;
}

.user-input {
  border-radius: 4px !important;
}

.user-input::placeholder {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.continue-btn {
  width: 100%;
  background-color: #0083c6 !important;
  color: #ffffff !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  border: 1px solid #0083c6;
}

.info-div {
  padding: 0.5rem 1.75rem;
  margin-bottom: 5rem;
  background: rgba(238, 251, 255, 0.65);
  border-radius: 4px;
}

.seeking {
  margin-bottom: 8px;
  font-size: 12px !important;
  font-weight: 600;
  color: #4c656f;
}

.for-any-help {
  margin-bottom: 10px;
  font-size: 10px !important;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.7);
}

.info-div-signup {
  padding: 0.5rem 1.75rem;
  background-color: aliceblue;
}

/* Home Screen */

.header-block {
  padding: 1rem 1.5rem 0.75rem;
}

.itemCode-input {
  font-size: 12px;
  border-radius: 5px;
  opacity: 1;
}

.itemCode-input::placeholder {
  font-size: 10px !important;
}

.user-details {
  text-align: end;
}

.user-name {
  margin-bottom: 0px;
  padding-left: 0.5rem;
  color: #00bcd4;
  font-weight: 600;
}

.info-block {
  padding: 6px;
  background-color: #ffd07b54;
  text-align: center;
}

.info-text {
  margin-bottom: 0px;
  font-size: 10px;
  font-weight: 600;
}

.items-block {
  height: 46vh;
  padding: 1rem 1rem 0rem 1.5rem;
  background-color: #f5f9fb;
  text-align: center;
}

.prodCardsScroll {
  height: 20vh;
}

.close-icon {
  width: 15px;
  position: relative;
  top: 0px;
  z-index: 1;
  left: 52px;
}

.prod-discount-home {
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 15px;
  color: #019f3e;
}

.totalCard-home {
  border: 1px solid rgba(196, 196, 196, 0.4);
  box-shadow: 2px 3px 4px #ededed;
  border-radius: 10px;
}

.subtotal-details-div-home {
  padding: 3px 10px;
  margin-bottom: 4.5rem;
  background: #f5f9fb;
  border-radius: 6px;
}

.total-details-div-home {
  padding: 3px 10px;
  margin-bottom: 1rem;
  background: #f5f9fb;
  border-radius: 6px;
}

.discount-price-home {
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 17px;
  color: #019f3e;
  text-align: right;
}

.continue-btn-cart-home {
  width: 100%;
  background-color: #0083c6 !important;
  color: #ffffff !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 4px;
  border: 1px solid #0083c6;
}

.current-summary {
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #000000;
  text-align: left;
}

.cards-margin {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.productCard {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
}

.empty-cart {
  height: 150px;
  width: 150px;
  display: block;
  margin: auto;
  background-color: hsl(0, 0%, 90%);
  transition: background-color 300ms;
  border-radius: 50%;
}

.no-items {
  margin-top: 5px;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 27px;
  color: #000000;
}

.start-adding {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.6);
}

.suggestedItems-block {
  padding: 0.5rem 1rem;
  background: #ffffff;
  box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.05);
}

.suggested {
  font-style: normal;
  font-size: 10px;
  line-height: 17px;
  color: #000000;
}

.suggested-items {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 17px;
  color: #000000;
}

.suggest-img-block {
  padding: 10px;
  background-color: #f6f6f6;
}

.suggest-item-card {
  border: 1px solid rgba(196, 196, 196, 0.51);
  border-radius: 10px;
  text-align: center;
}

.suggest-item-card-home {
  margin-top: -12px;
  border: 1px solid rgba(196, 196, 196, 0.35);
  box-shadow: 3px 3px 6px rgba(196, 196, 196, 0.24);
  border-radius: 6px;
}

.suggest-item,
.suggest-item-home {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.8);
  text-align: left;
   /* width: auto;
  max-width: 100% !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative; */

}

.suggest-item-price,
.suggest-item-price-home {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  text-align: right;
  color: #0083c6;
}

.suggest-item-home,
.suggest-item-price-home {
  margin-bottom: 5px;
  font-size: 9px;
}

.item-code {
  margin-bottom: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.8);
  text-align: left;
}

.prod-inc {
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  color: #0083c6;
  border: 1px solid rgba(196, 196, 196, 0.4);
  border-radius: 62px;
}

.suggest-addBtn,
.suggest-addBtn:hover,
.suggest-addBtn:focus {
  width: 100%;
  background: #0083c6;
  border: 1px solid #0083c6;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-size: 12px;
  color: #ffffff;
  font-weight: 600;
}

/* Bag Selection */

.bag-gif-block {
  padding: 1rem 2rem;
}

.bag-img {
  display: block;
  margin: auto;
  background-color: hsl(0, 0%, 90%);
  transition: background-color 300ms;
}

.bag-selection-block {
  height: 75vh;
  padding: 1rem 8rem;
  background: #f5f9fb;
  text-align: center;
}

.please-select-bag {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 52px;
  color: #000000;
}

.qty-inc-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bagSelection-addBtn,
.bagSelection-addBtn:hover,
.bagSelection-addBtn:focus {
  width: 70%;
  margin-top: 0.5rem;
  background: #0083c6;
  border: 1px solid #0083c6;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-size: 10px;
  color: #ffffff;
  font-weight: 600;
}

/* Payment Screen */

.card-payment-img {
  display: block;
  margin: auto;
  background-color: hsl(0, 0%, 90%);
  transition: background-color 300ms;
}

.payment-gif-block {
  height: 75vh;
  padding: 4rem 0rem;
  background: #f5f9fb;
}

.payment-method-block {
  text-align: center;
  align-self: center;
}

.waiting-img {
  display: block;
  margin: auto;
  background-color: hsl(0, 0%, 90%);
  transition: background-color 300ms;
  cursor: pointer;
}

.please-insert {
  margin-bottom: 1.5rem;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 37px;
  text-align: center;
  color: #000000;
}

.remaining-time {
  margin-top: 1rem;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #f76148;
  text-align: center;
}

.seconds-left {
  margin-bottom: 1.5rem;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  color: #929292;
  text-align: center;
}

.go-back {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 22px;
  text-align: center;
  color: #0083c6;
}

.payment-successfull {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: #000000;
}

/* Bill Type Modes Screen */

.where-would {
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
}

.tick-icon {
  height: 35px;
  width: 35px;
  position: absolute;
  bottom: 205px;
  left: 170px;
}

.billmode-card-active {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: 6px 7px 8px #e7e7e7;
  border-radius: 15px;
  z-index: -1;
}

.billmode-card {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: 0px 0px 8px #e7e7e7;
  border-radius: 15px;
  z-index: -1;
}

.billmode-img-div {
  padding: 2rem 1rem;
  background: #eaf3f8;
  border-radius: 10px;
}

.bill-mode-img {
  width: 100px;
  height: 100px;
}

.bill-mode-text {
  margin-top: 1rem;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.6);
}

.billmode-addBtn,
.billmode-addBtn:hover,
.billmode-addBtn:focus {
  width: 15%;
  margin-top: 0.5rem;
  background: #0083c6;
  border: 1px solid #0083c6;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-size: 10px;
  color: #ffffff;
  font-weight: 600;
}

/* Feedback Screens */

.what-would {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
}

.tick-icon-feedback {
  height: 35px;
  width: 35px;
  position: absolute;
  bottom: 75px;
  left: 280px;
}

.proceed-nextBtn,
.proceed-nextBtn:hover,
.proceed-nextBtn:focus {
  width: 12%;
  margin-top: 0.5rem;
  background: #0083c6;
  border: 1px solid #0083c6;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-size: 10px;
  color: #ffffff;
  font-weight: 600;
}

.feedback-card-active {
  background: #ffffff;
  border: 1px solid #0083c6;
  box-shadow: 6px 7px 8px #e7e7e7;
  border-radius: 15px;
  z-index: -1;
}

.feedback-card {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: 0px 0px 8px #e7e7e7;
  border-radius: 15px;
  z-index: -1;
}

.feedback-img-div {
  padding: 2rem 1rem;
  background: #fef9e9;
  border-radius: 10px;
}

.emoji-card-active {
  background: #ffffff;
  border: 1px solid #eab000;
  box-shadow: 6px 7px 8px #e7e7e7;
  border-radius: 15px;
  z-index: -1;
}

.tick-icon-emoji {
  height: 35px;
  width: 35px;
  position: absolute;
  bottom: 205px;
  left: 170px;
}

.thank-you {
  margin-bottom: 2rem;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
}

/* Payment Selection Screen */

.payment-select-block {
  height: 75vh;
  padding: 1rem 2rem;
  background: #f5f9fb;
}

.payment-selection-card {
  height: 45vh;
  background: #ffffff;
  border: 1px solid rgba(196, 196, 196, 0.53);
  border-radius: 15px;
  text-align: center;
}

.payment-selection-active-card {
  height: 45vh;
  background: #ffffff;
  border: 1px solid rgba(196, 196, 196, 0.4);
  box-shadow: 0px 0px 4px #f6f6f6, 3px 3px 10px #c4c4c4;
  border-radius: 15px;
  text-align: center;
}

.tick-icon-payment {
  height: 35px;
  width: 35px;
  position: absolute;
  bottom: 265px;
  left: 170px;
  z-index: 1;
}

.card-payment-gif {
  height: 100px;
  width: 100%;
  /* display: block;
  margin: auto; */
  background-color: hsl(0, 0%, 90%);
  transition: background-color 300ms;
}

.payment-mode-text {
  margin-top: 2rem;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.6);
}

.payment-selection-card-gif {
  width: 100%;
  height: 120px;
}

.payment-select-desc {
  padding: 1.5rem 1rem;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: rgba(51, 51, 51, 0.8);
}

.current-order-block {
  padding: 1rem 1rem 2rem 2rem;
}

.img-col {
  background-color: #f6f6f6;
  border-radius: 4px;
}

.current-order {
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}

.prod-name {
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  color: #000000;
}

.prod-qty {
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.5);
}

.prod-price {
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  text-align: right;
  color: #0083c6;
}

.prod-discount {
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #019f3e;
  text-align: right;
}

.items-text {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.5);
}

.total-details-div {
  padding: 10px;
  margin: 1rem 1rem 1rem 0rem;
  background: #f5f9fb;
  border-radius: 6px;
}

.subtotal {
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 17px;
  color: #000000;
}

.subtotal-price {
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 17px;
  color: #000000;
  text-align: right;
}

.discount-label {
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 17px;
  color: #019f3e;
}

.discount-price {
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #019f3e;
  text-align: right;
}

.total {
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  color: #000000;
}

.total-price {
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  text-align: right;
  color: #000000;
}

.continue-btn-cart {
  width: 95%;
  background-color: #0083c6 !important;
  color: #ffffff !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  border: 1px solid #0083c6;
}

/* Payment cancel modal */

.are-you {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.7);
}

.yesCancelBtn, .yesCancelBtn:hover, .yesCancelBtn:focus {
  width: 35%;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  border: none;
  color: #000000;
}

.no-btn, .no-btn:hover, .no-btn:focus {
  width: 45%;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  color: #ffffff;
  border: 1px solid #0083c6;
  background: #0083c6;
  border-radius: 3px;
}
