.ant-form-item-label > label.ant-form-item-required:before {
  content: unset !important;
}

/* .ant-form-item-label {
     font-size: 10px !important;
 } */

.ant-form-item-label > label {
  font-size: 12px !important;
  display: initial;
}

/* Home page steps */

.ant-steps-small .ant-steps-item-title {
  font-size: 12px;
}

.ant-steps-item-wait .ant-steps-item-icon {
  background: #adadad;
  border: none;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon
  .ant-steps-icon {
  color: #ffffff !important;
  font-weight: 600 !important;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #ffffff !important;
  font-weight: 600 !important;
}

.ant-steps-item-process .ant-steps-item-icon {
  border: none;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #0083c6;
  /* border-color: #1890ff; */
  border: none;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #ffffff !important;
  font-weight: 600 !important;
}

.ant-steps-item-description {
  line-height: 12px;
  color: rgba(0, 0, 0, 0.6);
}

.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-title,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-subtitle,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.6);
}

.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-icon {
  border: none;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  color: #000000;
}

.ant-badge-status-success {
  background-color: #4caf50;
}

.ant-badge-status-dot {
  top: 1px;
  width: 5px;
  height: 5px;
}

.ant-badge-status-text {
  margin-left: 4px;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.6);
}
